/* file-autogenerated by @takeda-digital/xr on 2024-06-10 at 17:27:55.989 UTC */
"use client";
import type { SyntheticEvent } from "react";
import { useCallback, useRef, useState } from "react";
import useSWR from "swr";
import type { UserAgentProps } from "@takeda-digital/xr";
import { projectTitle, projectVersion } from "@/lib/constants";

const fetcher = (input: RequestInfo, init?: RequestInit): Promise<any> =>
  fetch(input, init).then(res => res.json());

function UseSwrSync({ hasData }: { hasData: boolean }) {
  const { data, isValidating, isLoading, error } = useSWR<
    UserAgentProps | undefined,
    unknown
  >(hasData === false ? "/api?code=yes" : null, fetcher, {
    refreshInterval: 100000,
    fetcher: fetcher
  });

  return {
    data,
    error,
    isLoading,
    isValidating
  };
}
export function ClientStreamer() {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const [hasData, setHasData] = useState(false);
  const [_device, setDevice] = useState<string | undefined>();
  const [_browser, setBrowser] = useState<string | undefined>();
  const { data } = UseSwrSync({ hasData });

  /**
   * target the fullscreen button in embedded content document -- conditionally remove node IF (1) _mobile_ AND (2) _not chrome_
   */
  const writeHTML = useCallback((props: HTMLIFrameElement | null) => {
    if (!props) {
      return;
    }
    iFrameRef.current = props;
    // props.style.width = "100%";
    // props.style.height = props?.contentWindow?.document?.body?.scrollHeight ? props.contentWindow.document.body.scrollHeight?.toString() : "100%"
    // if (
    //   typeof device !== "undefined" &&
    //   device.includes("mobile") &&
    //   props.contentDocument?.getElementById("303")
    // ) {
    //   props.contentDocument?.getElementById("303")?.remove();
    //   return props;
    // }
    props;
  }, []);

  const dataCb = useCallback((data: UserAgentProps) => {
    if (data?.browser?.name && data?.device?.type) {
      setBrowser(data.browser.name);
      setDevice(data.device.type);
      return data;
    } else if (!data?.device?.type && data.browser.name) {
      setBrowser(data.browser.name);
      return data;
    } else if (!data.browser.name && data.device.type) {
      setDevice(data.device.type);
      return data;
    } else {
      return data;
    }
  }, []);

  const useOnLoadCb = useCallback(
    (e: SyntheticEvent<HTMLIFrameElement, Event>) => {
      e.preventDefault();
      if (typeof data !== "undefined") {
        setHasData(true);
        dataCb(data);
      } else {
        return;
      }
    },
    [data, dataCb]
  );

  return (
    <>
      <iframe
        onLoad={useOnLoadCb}
        ref={writeHTML}
        src={`/index.htm?v=${projectVersion}`}
        allowFullScreen={true}
        width='100%'
        height='100%'
        title={projectTitle}
        style={{
          padding: 0,
          margin: 0,
          overflow: "hidden",
          borderWidth: 0,
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          touchAction: "manipulation",
          border: 0
        }}
        suppressContentEditableWarning
        suppressHydrationWarning
        loading='eager'
        allow='fullscreen; accelerometer; gyroscope; magnetometer; vr; autoplay; camera; microphone; display-capture; xr-spatial-tracking; document-domain; encrypted-media;'
      />
    </>
  );
}
